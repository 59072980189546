<template>
  <div class="screen-alert-container">
    <b-modal
      :ref="alertId"
      :modal-class="`screen-alert ${config.type} ${config.preventHide ? 'prevent-hide' : ''}`"
      :title="config.title"
      hide-footer
      @hide="onHide"
    >
      <template #modal-header-close="{}">
        <div></div>
      </template>

      <div v-if="bodyIcon" class="body-icon">
        <b-img :src="require(`@/assets/img/icons/${bodyIcon}.svg`)" alt="icon" width="60" />
      </div>

      <div class="content" v-html="config.content"></div>

      <div v-if="config.cancelLabel || config.confirmLabel" class="actions">
        <b-button v-if="config.cancelLabel" block variant="light" @click="onCancel">{{ config.cancelLabel }}</b-button>
        <b-button v-if="config.confirmLabel" block variant="primary" @click="onConfirm">{{ config.confirmLabel }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import bus from '@/api/bus';

export default {
  data() {
    return {
      alertId: 'screenAlert',
      config: {
        type: 'info',
        title: this.$t('components.screenAlert.title'),
        content: '',
        icon: '',
        confirmLabel: '',
        cancelLabel: '',
        preventHide: false,
        onConfirm: () => {},
        onClose: () => {},
      },
    };
  },

  computed: {
    bodyIcon() {
      return this.config.icon;
    },
  },

  methods: {
    onHide(bvModalEvt) {
      if (this.config.preventHide) bvModalEvt.preventDefault();
      else this.config.onClose();
    },

    onConfirm() {
      this.onCancel();
      this.config.onConfirm();
    },

    onCancel() {
      this.$refs[this.alertId].hide();
    },
  },

  mounted() {
    bus.$on('showAlert', (config) => {
      this.config = { ...this.config, ...config };
      if (this.$refs[this.alertId]) this.$refs[this.alertId].show();
    });

    bus.$on('hideAlert', () => {
      this.config.preventHide = false;
      this.onCancel();
    });
  },
};
</script>

<style lang="scss">
@import './ScreenAlert';
</style>
